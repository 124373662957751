/**
 * Matomo analytics
 */
var siteId = '1';
var matomoTrackingApiUrl = 'https://analytics.causeway.education/piwik.php';
var _paq = window._paq = window._paq || [];
_paq.push(['setTrackerUrl', matomoTrackingApiUrl]);
_paq.push(['setSiteId', siteId]);
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(["setDomains", ["oscar.causeway.education"]]);
_paq.push(['setSecureCookie', true]);
// Set userId if someone is logged in
let isUid = document.querySelector('[data-uid]');
let uid;
if (isUid) {
    uid = isUid.dataset.uid;
}
if (uid) {
    _paq.push(['setUserId', uid]);
}
// more accurately track on page time
_paq.push(['enableHeartBeatTimer']);
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
